import { resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "my-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Form = _resolveComponent("Form")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createBlock(_component_Form, { class: "form" }, {
    default: _withCtx(() => [
      _withDirectives(_createElementVNode("div", _hoisted_1, null, 512), [
        [_directive_loading, _ctx.tahir]
      ])
    ]),
    _: 1
  }))
}