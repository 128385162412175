
import { defineComponent } from 'vue';
import ApiService from '@/core/services/ApiService';
import { apiEndpoint } from '@/mixin/apiMixin.js';
import axios from 'axios';
import { AnyObject } from 'yup/lib/object';
import { VueCookieNext } from 'vue-cookie-next';
export default defineComponent({
  mixins: [apiEndpoint],
  name: 'finman-invoice',
  components: {},
  data() {
    return {
      tahir: false,

      finmanUserId: '' as any,
      finmantoken: '' as any,
    };
  },
  async created() {
    await this.finmanAuthCheck();
  },
  methods: {
    async finmanAuthCheck() {
      this.tahir = true;
      // get token from request params;
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      if (urlParams.get('finmantoken')) {
        this.tahir = true;
        //application access
        this.finmanUserId= urlParams.get('finmantoken');
        this.finmantoken = 'DTL@2023!';
        let formdata = new FormData();
        formdata.set('finmantoken', this.finmantoken);
        formdata.set('finmanuser', this.finmanUserId);

        axios
          .post(`${this.VUE_FINMAN_AUTH_API}`, formdata)
          .then((response) => {
            if (response.data.status == 'success') {
              // set token  in browser

              VueCookieNext.setCookie(
                'finman_token',
                response.data.data.finmantoken
              );
              VueCookieNext.setCookie('user_id', response.data.data.user.id);

              this.$router.push({
                name: 'add-invoice', //use name for router push
              });
              this.tahir = false;
            } else {
              // token not match redirect to   404
              this.$router.push('/404');
            }
          })
          .catch((error) => {
            // api not responde
            this.$router.push('/404');
            console.error('There was an error!', error);
          });
      } else {
        //if not token found  redirect to 404 pages
        this.$router.push('/404');
      }
    },
  },
});
